<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                3x3 Technologies: Communicating
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="discord"
                        :index="index"
                        @close="index = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in discord"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="Discord Video"
                          />
                          <a
                            @click="index = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Communicating With:</span>
                        <h2 class="heading-title">
                          <a href="https://discord.com/" target="_blank"
                            >Discord</a
                          >
                        </h2>
                        <p class="description">
                          Discord is a voice, video, and text chat app that's
                          used by tens of millions of people ages 13+ to talk
                          and hang out with their communities and friends.
                          People use Discord daily to talk about many things,
                          ranging from art projects and family trips to homework
                          and mental health support. It was targeted at gaming
                          communities, but it became one of the most used
                          communicating tools when the pandemic hit.
                        </p>
                        <p class="description">
                          The great thing about Discord is it is free. This
                          allows everyone to use and start communicating in no
                          time. All you have to do is set up an account, and you
                          can jump right in. Another nice thing is that Discord
                          is supported on all devices. You can use Discord on
                          Windows or Mac, iOS, or Android devices, and it even
                          supports running in the browser. Discord as a
                          communication tool allows you to create or join
                          servers specific to the needs of someone. This can
                          enable students to have a one-on-one conversation with
                          an instructor or another student. An instructor could
                          even have an office hours server where students can
                          drop in anytime.
                        </p>
                        <p class="description">
                          Discord is a communication tool that isn't specific to
                          any group. It can be for school clubs, gaming groups,
                          higher-education courses, or worldwide communities.
                          This makes it the ultimate communication tool by being
                          available to anyone.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--20">
                      <p class="description">
                        Discord as a communication tool has a simper user
                        interface and is easy to use but can be more advanced.
                        Being easy to use allows anyone to jump right in, but
                        Discord recommends 13+. Being an easy-to-use tool allows
                        anyone to quickly jump into Discord and create a server
                        or find a server by just clicking an invite link. I
                        recommend using this software to anyone because of the
                        ease of use, and I've even created an office hour server
                        for all my classes where students come in anytime to ask
                        questions or even talk to each other. An example of this
                        is I have multiple channels, and these channels are all
                        for the different sections of the courses I teach. I
                        also have an area for private voice chat where we can
                        talk together. Having these is just a small portion. I
                        can also video chat and stream my screen. Also, when a
                        common issue arises that multiple students ask, I can
                        pin that comment so everyone can see the solution.
                        Discord also allows integration with bots to allow
                        custom commands to help moderate your channels.
                      </p>
                      <p class="description">
                        I believe Discord promotes and supports the intended
                        learning outcomes when it comes to communication in the
                        classroom or in general. Using a tool that students are
                        already used to using, I feel, keeps students engaged,
                        and I have noticed they talk more. Having engaged
                        communication is critical with the switch to online
                        learning, and I think that is where a tool like this
                        strives. If you are looking for a tool to communicate
                        with students and have all the abilities needed to make
                        sure a student gets the best outcome, I think this is
                        the communication tool to use.
                      </p>
                      <p>
                        Below is a YouTube video that demonstrates how to setup
                        and get started with Discord. Once up and going you will
                        be able to communicate in multiple different areas and
                        interests.
                      </p>
                      <p>
                        <iframe
                          width="100%"
                          height="600"
                          src="https://www.youtube.com/embed/OIqyPJQAgT4"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="rn-service-details ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col
                    lg="6"
                    md="12"
                    cols="12"
                    order="1"
                    order-md="1"
                    order-lg="2"
                  >
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="slack"
                        :index="slackindex"
                        @close="slackindex = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in slack"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="Slack Video"
                          />
                          <a
                            @click="slackindex = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    lg="6"
                    md="12"
                    cols="12"
                    order="2"
                    order-md="2"
                    order-lg="1"
                  >
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Communicating With:</span>
                        <h2 class="heading-title">
                          <a href="https://slack.com/" target="_blank">Slack</a>
                        </h2>
                        <p class="description">
                          Slack is a messaging app for business that connects
                          people to the information they need. By bringing
                          people together to work as one unified team, Slack
                          transforms the way organizations communicate.
                        </p>
                        <p class="description">
                          The great thing about Slack is that it has a free tier
                          with limitations to get hands-on experience. It is
                          also one of the most used industry-standard
                          communication tools in the software area. Slack is
                          supported on all devices such as Windows, macOS, iOS,
                          and Android. Having access to all platforms makes it a
                          great communication tool. As with most communication
                          tools Slack allows large chats and one-on-one
                          conversations. One feature that makes Slack stand out
                          is its functionality to integrate applications into
                          the communication tool.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12" order="3" order-md="3">
                    <div class="details mt_md--30 mt_sm--30 pt--30">
                      <p class="description">
                        Having the ability to integrate apps allows for a more
                        professional communication environment. This allows
                        integrating things like Google Drive or Adobe Creative
                        Cloud. This allows the users to find and share projects
                        they are working on quickly to discuss and talk about
                        any item needed. That is just a tiny piece of all the
                        integrations that can be done. It has many categories of
                        integration like analytics, customer support, design,
                        developer tools, file management, health and wellness,
                        finance, sales, and much more. Having a wide range of
                        integration tools allows the process of communication to
                        go very far. Even though this communication tool is
                        targeted towards business professionals, it is an
                        excellent tool to include in a classroom setting because
                        this gives students hands-on an industry-standard tool.
                      </p>
                      <p class="description">
                        I have used Slack in my programming classes. It has been
                        a great communication tool because students can interact
                        with me and ask questions. The great thing, though, is
                        the integration of GitHub. This allows students to
                        quickly postcode in the chat where I can help walk
                        through with them and help them fix the problem. Just
                        with that integration, it makes the tool worth it.
                      </p>
                      <p class="description">
                        I believe Slack promotes and supports the intended
                        learning outcomes expected from students in the Computer
                        Science realm because it shows they can communicate
                        problems effectively. It gives them experience using an
                        industry-level tool that they will use when they get
                        hired as a programmer. Using a tool like this in
                        students' learning allows us to measure the students'
                        success in communicating effectively.
                      </p>
                      <p class="description">
                        Below is a YouTube video that demonstrates how to setup
                        and get started with Slack. Once up and going you will
                        be able to professionally communicate.
                      </p>
                      <p class="description">
                        <iframe
                          width="100%"
                          height="650"
                          src="https://www.youtube.com/embed/RRxQQxiM7AA"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="work"
                        :index="workindex"
                        @close="workindex = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in work"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="Figma Video"
                          />
                          <a
                            @click="workindex = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Communicating With:</span>
                        <h2 class="heading-title">
                          <a
                            href="https://www.oculus.com/workrooms/"
                            target="_blank"
                            >Horizon Workrooms</a
                          >
                        </h2>
                        <p class="description">
                          Horizon Workrooms is the VR space for teams to
                          connect, communicate and develop ideas together. Meet
                          teammates across the table, even if you're across the
                          world. Horizon Workrooms does require an Oculus Quest
                          2, but this is a very future communication tool, even
                          though it is much more than that as it incorporates
                          collaboration as well.
                        </p>
                        <p class="description">
                          Horizon Workrooms is technically a free tool, but
                          since it requires an Oculus Quest 2, I feel like this
                          is the payment needed in order to use the tool. I have
                          been lucky to have some headsets to test this tool
                          out, and I find it fascinating what the future could
                          bring. With Workrooms being a part of Facebook, it has
                          access to a large community, but the application is
                          focused on communicating with groups close to you. As
                          of right now, Workrooms supports groups of 16 or less.
                          This makes it a good communication tool for smaller
                          group-type projects where people can't be in the same
                          room. Workrooms can be used as a collaboration tool,
                          but the base use is communicating.
                        </p>
                        <p class="description">
                          The target audience for Horizon Workrooms is for
                          anyone who needs to communicate and has access to an
                          Oculus Quest 2. it allows users to voice chat in a
                          professional setting like a meeting room. It also
                          allows users to display themselves as avatars,
                          enabling others to see how they would like instead of
                          just a username like traditional communication tools.
                          I would recommend anyone who needs to communicate and
                          has a VR headset to give it a try as I think VR is a
                          way of the future.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--30">
                      <p class="description">
                        I have used Horizon Workrooms personally but not in the
                        classroom due to the limited ability to have a VR
                        headset for everyone. I have been able to experience it
                        with friends, and it gives a great environment to
                        communicate with others. It was very easy to jump right
                        in and join a workroom with my friends. Once in the
                        room, we were able to see each other's avatars and start
                        talking. This is such a small part of the tool because
                        it allows for other things like putting a pen on a
                        whiteboard. You can bring your physical desktop into the
                        virtual space and present your desktop virtually so
                        others can see it. Jumping into a workroom felt like I
                        was there. Even with the cartoon-looking avatars, I felt
                        like I was present because the communication felt real.
                      </p>
                      <p class="description">
                        I believe Horizon Workrooms promotes and supports the
                        intended learning outcomes for students trying to
                        communicate. If I could provide a VR headset to all
                        students, I would implement a tool like this due to the
                        immersion and the feeling of really being there. Also,
                        allowing everyone to communicate or present something
                        that they are working on quickly makes it a powerful
                        tool. Even though it isn't doable, I look forward to the
                        day I could implement such technology into my online
                        courses.
                      </p>
                      <p class="description">
                        Below is a YouTube video that demonstrates how to setup
                        and get started with Horizon Workspaces on Quest 2. Once
                        up and going you will be able to professionally
                        communicate.
                      </p>
                      <p class="description">
                        <iframe
                          width="100%"
                          height="650"
                          src="https://www.youtube.com/embed/018SlWhRMkg"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p></div
                  ></v-col>
                </v-row>
                <div class="portfolio-details-btn mt--30">
                  <router-link class="btn-default btn-border" to="/LDTE5020"
                    >Back to LDTE 5020</router-link
                  >
                </div>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
import feather from "feather-icons";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      discord: [
        {
          thumb: require("../../assets/images/discord.png"),
          src: "https://youtu.be/TJ13BA3-NR4",
        },
      ],
      slack: [
        {
          thumb: require("../../assets/images/slack.png"),
          src: "https://youtu.be/q19RtuCHt1Q",
        },
      ],
      work: [
        {
          thumb: require("../../assets/images/work.png"),
          src: "https://youtu.be/lgj50IxRrKQ",
        },
      ],
      index: null,
      slackindex: null,
      workindex: null,
    };
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: black !important;
}
</style>
